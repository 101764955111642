<template>
  <div>
    <form @submit.prevent="save()">
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Nombre</label>
            <vs-input
              v-model="typeform.name"
              placeholder="Nombre"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.name.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.name.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.name.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Provider Id</label>
            <vs-input
              v-model="typeform.providerId"
              placeholder="Provider Id"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.providerId.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.providerId.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.providerId.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Duración del espacio de apuestas (En Segundos)</label>
            <vs-input
              v-model="typeform.roundDuration"
              placeholder="Duración del espacio de apuestas"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.roundDuration.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.roundDuration.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.roundDuration.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Minutos para desactivar la mesa</label>
            <vs-input
              v-model="typeform.minutesToDisable"
              placeholder="Minutos para desactivar la ruleta"
              border
              class="shadow-lg"
              name="minutesToDisable"
            />
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <label>Link Live Video</label>
          <vs-input
            v-model="typeform.urlTransmision"
            placeholder="Link Live Video"
            border
            class="shadow-lg"
            :class="{
              'is-invalid': typesubmit && $v.typeform.urlTransmision.$error,
            }"
          />
          <div
            v-if="typesubmit && $v.typeform.urlTransmision.$error"
            class="invalid-feedback"
          ></div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Crupier</label>
            <div>
              <select
                v-model="typeform.crupier"
                name="crupier"
                class="form-select border-0 shadow-lg"
                :class="{
                  'is-invalid': typesubmit && $v.typeform.crupier.$error,
                }"
                placeholder="Seleccione.."
              >
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option
                  v-for="(item, index) in crupiers"
                  :key="index"
                  :value="item._id"
                  :selected="typeform.crupier == item._id"
                >
                  {{ item.name }}
                </option>
              </select>
              <div
                v-if="typesubmit && $v.typeform.crupier.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.crupier.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-4 mt-3"
        ><vs-checkbox v-model="typeform.jackpot"> Jackpot </vs-checkbox>
      </b-row>
      <div class="mb-3 mb-0">
        <div class="d-flex">
          <vs-button type="submit">Guardar</vs-button>
          <vs-button @click="$emit('closeModa')" type="button" success>
            Cancelar
          </vs-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';

export default {
  props: {
    typeform: {
      type: Object,
      default: () => {
        return {};
      },
    },
    crupiers: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      providerId: {
        required,
      },
      crupier: {},
      jackpot: {},
      roundDuration: {
        required,
      },
      minutesToDisable: {
        required,
      },
      urlTransmision: {
        required,
      },
      doubleZero: {
        required,
      },
    },
  },
  methods: {
    save() {
      this.typesubmit = true;
      console.log('Enviar', this.typeform);
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('error');
      } else {
        if (this.typeform._id) {
          this.$http
            .put(`roulettes/fisics/${this.typeform._id}`, {
              ...this.typeform,
            })
            .then((response) => {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Ruleta fisica guardada con exito',
                showConfirmButton: false,
                timer: 1500,
              });
              console.log(response);
              this.$emit('closeModa');
            })
            .catch((error) => {
              console.log(error);
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Error',
                showConfirmButton: false,
                timer: 1500,
              });
            });
        } else {
          this.$http
            .post('roulettes/fisics', {
              ...this.typeform,
            })
            .then((response) => {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Ruleta fisica registrada con exito',
                showConfirmButton: false,
                timer: 1500,
              });
              console.log(response);
              this.$emit('closeModa');
            })
            .catch((error) => {
              console.log(error);
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Error',
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      }
    },
  },
};
</script>
