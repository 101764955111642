var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Nombre")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.name.$error,
            },attrs:{"placeholder":"Nombre","border":""},model:{value:(_vm.typeform.name),callback:function ($$v) {_vm.$set(_vm.typeform, "name", $$v)},expression:"typeform.name"}}),(_vm.typesubmit && _vm.$v.typeform.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.name.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Provider Id")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.providerId.$error,
            },attrs:{"placeholder":"Provider Id","border":""},model:{value:(_vm.typeform.providerId),callback:function ($$v) {_vm.$set(_vm.typeform, "providerId", $$v)},expression:"typeform.providerId"}}),(_vm.typesubmit && _vm.$v.typeform.providerId.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.providerId.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Duración del espacio de apuestas (En Segundos)")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.roundDuration.$error,
            },attrs:{"placeholder":"Duración del espacio de apuestas","border":""},model:{value:(_vm.typeform.roundDuration),callback:function ($$v) {_vm.$set(_vm.typeform, "roundDuration", $$v)},expression:"typeform.roundDuration"}}),(_vm.typesubmit && _vm.$v.typeform.roundDuration.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.roundDuration.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Minutos para desactivar la mesa")]),_c('vs-input',{staticClass:"shadow-lg",attrs:{"placeholder":"Minutos para desactivar la ruleta","border":"","name":"minutesToDisable"},model:{value:(_vm.typeform.minutesToDisable),callback:function ($$v) {_vm.$set(_vm.typeform, "minutesToDisable", $$v)},expression:"typeform.minutesToDisable"}})],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('label',[_vm._v("Link Live Video")]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.urlTransmision.$error,
          },attrs:{"placeholder":"Link Live Video","border":""},model:{value:(_vm.typeform.urlTransmision),callback:function ($$v) {_vm.$set(_vm.typeform, "urlTransmision", $$v)},expression:"typeform.urlTransmision"}}),(_vm.typesubmit && _vm.$v.typeform.urlTransmision.$error)?_c('div',{staticClass:"invalid-feedback"}):_vm._e()],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Crupier")]),_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.crupier),expression:"typeform.crupier"}],staticClass:"form-select border-0 shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.crupier.$error,
              },attrs:{"name":"crupier","placeholder":"Seleccione.."},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "crupier", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v(_vm._s(_vm.$t('helpers.select'))+"...")]),_vm._l((_vm.crupiers),function(item,index){return _c('option',{key:index,domProps:{"value":item._id,"selected":_vm.typeform.crupier == item._id}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2),(_vm.typesubmit && _vm.$v.typeform.crupier.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.crupier.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()])])])],1),_c('b-row',{staticClass:"mb-4 mt-3"},[_c('vs-checkbox',{model:{value:(_vm.typeform.jackpot),callback:function ($$v) {_vm.$set(_vm.typeform, "jackpot", $$v)},expression:"typeform.jackpot"}},[_vm._v(" Jackpot ")])],1),_c('div',{staticClass:"mb-3 mb-0"},[_c('div',{staticClass:"d-flex"},[_c('vs-button',{attrs:{"type":"submit"}},[_vm._v("Guardar")]),_c('vs-button',{attrs:{"type":"button","success":""},on:{"click":function($event){return _vm.$emit('closeModa')}}},[_vm._v(" Cancelar ")])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }